import React, { useEffect } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import BackButton from "../../components/Base/ButtonLink/BackButton";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { adminRoute } from "../../utils";
import TBAPoolForm from "./TBAPoolForm";
import { useSelector } from "react-redux";

const TBAPoolCreate: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);
  
  return (
    <DefaultLayout>
      <BackButton to={adminRoute("/tba-campaigns")} />
      <TBAPoolForm isEdit={false} />
    </DefaultLayout>
  );
};

export default withRouter(TBAPoolCreate);
