import React, { useEffect } from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import {adminRoute} from "../../utils";
import PoolForm from "./PoolForm";
import BackButton from "../../components/Base/ButtonLink/BackButton";
import { useSelector } from 'react-redux';

const PoolCreate: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = false;

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/campaigns')}/>
      <PoolForm
        isEdit={isEdit}
      />
    </DefaultLayout>
  )
}

export default withRouter(PoolCreate);
