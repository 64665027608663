import { useEffect, useState } from "react";
import ButtonLink from "../../components/Base/ButtonLink";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { adminRoute } from "../../utils";
import useStyles from "./style";
import { getSupply } from "../../request/supply";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const tableHeaders = [
  "TOKEN ADDRESS",
  "WALLET ADDRESS",
  "CHAIN",
  "TYPE",
  "NAME",
];

const Supply: React.FC = () => {
  const classes = useStyles();
  const [listSupply, setListSupply] = useState<any>();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  const getSupplyQuery = async () => {
    try {
      setLoading(true);
      const result = await getSupply();
      if (result?.data) {
        setListSupply(result?.data?.data);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupplyQuery();
  }, []);

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <ButtonLink
            to={adminRoute("/supply-create")}
            text={"Create New Supply"}
            icon={"icon_plus.svg"}
          />
        </div>
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={"100%"} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {listSupply &&
                listSupply?.length > 0 &&
                listSupply?.map((supply: any, index: number) => (
                  <SupplyRow key={supply.id} supply={supply} />
                ))}
            </TableBody>
          </Table>
        )}
        {/* {failure ? (
          <p className={classes.errorMessage}>{failure}</p>
        ) : (!admins || admins.length === 0) && !loading ? (
          <p className={classes.noDataMessage}>There is no data</p>
        ) : (
          <>
            {admins && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classes.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )} */}
      </TableContainer>
    </DefaultLayout>
  );
};

export const SupplyRow = (props: any) => {
  const { supply } = props;
  const classes = useStyles();
  return (
    <TableRow
      className={classes.tableRow}
      component={Link}
      to={adminRoute(`/supply-detail/${supply?.id}`)}
    >
      <TableCell className={classes.tableCellTitle} component="td" scope="row">
        <span className={classes.wordBreak}>{supply?.token_address}</span>
      </TableCell>

      <TableCell className={classes.tableCellTitle} component="td" scope="row">
        <span className={classes.wordBreak}>{supply?.wallet_address}</span>
      </TableCell>

      <TableCell>
        <span>{supply?.chain}</span>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <span className={classes.wordBreak}>{supply?.type}</span>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <span className={classes.wordBreak}>{supply?.name}</span>
      </TableCell>
    </TableRow>
  );
};

export default Supply;
