import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";

export const importUser = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/import-new-user`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};
