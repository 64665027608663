export const getApiUrl = () => {
    const domain = window?.location?.hostname;

    if (domain?.includes('icetea-software') || domain?.includes('localhost') || domain?.includes('nguyentue')) {
        return 'https://rwa-lp-user.icetea-software.com/api';
    }
    const newDomain = domain?.replace('admin.', '');
    return 'https://api.' + newDomain;
};

export const getEnvironment = async () => {
    const url = getApiUrl();
    return fetch(`${url}/config`);
};
