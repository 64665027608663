import { Upload } from "antd";
import Pagination from "@material-ui/lab/Pagination";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import useStyles from "./style";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import { importUser } from "../../request/importData";
import { useDispatch, useSelector } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { useHistory } from "react-router-dom";

const DataList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { ref } = useComponentVisible();
  const dispatch = useDispatch();

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  const tableHeaders = ["WALLET ADDRESS"];

  const [fileData, setFiledata] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [walletDatas, totalPage, totalItem, allWallets] = useMemo(() => {
    const walletList = fileData?.slice(1).filter((item: string) => item !== "");
    const allWallets = fileData?.slice(1).filter((item: string) => item !== "");

    if (walletList?.length === 0) {
      return [[], 0, 0, []];
    }

    const totalItem = walletList.length;
    const totalPage = Math.ceil(walletList.length / 10);

    const start = (currentPage - 1) * 10;
    const data = walletList.splice(start, 10);

    return [data, totalPage, totalItem, allWallets];
  }, [fileData, currentPage]);

  const handleUploadfile = (file: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      setFiledata(e?.target.result.split(/\r?\n/));
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  const handleSendData = () => {
    const requestBody = { wallet_list: [...allWallets] };

    importUser(requestBody).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess("Import users successfully"));
        setFiledata([]);
      } else {
        dispatch(alertFailure(res.message || "Something went wrong"));
      }
    });
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Upload
            accept=".csv"
            showUploadList={false}
            beforeUpload={(file) => handleUploadfile(file)}
          >
            <button
              className={classes.exportBtn}
              style={{ color: "#000", marginLeft: "10px" }}
            >
              Import csv
            </button>
          </Upload>
        </div>
        <div className="header-right">
          <button
            className={classes.exportBtn}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={() => handleSendData()}
          >
            Send data
          </button>
        </div>
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader: string, index: number) => (
                <TableCell key={index} className={classes.tableHeader}>
                  {tableHeader}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {walletDatas?.map((item: string) => (
              <TableRow ref={ref} className={classes.tableRow}>
                <TableCell className={classes.tableCell} align="left">
                  {item}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <>
          {totalItem > 10 && (
            <Pagination
              page={currentPage}
              className={classes.pagination}
              count={totalPage}
              onChange={handlePaginationChange}
            />
          )}
        </>
      </TableContainer>
    </DefaultLayout>
  );
};

export default DataList;
