import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  ButtonBase,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import CachedIcon from "@material-ui/icons/Cached";
//@ts-ignore
import DatePicker from "react-date-picker";
import { debounce } from "lodash";
import { BaseRequest } from "../../request/Request";
import { apiRoute } from "../../utils";

import { convertDateTimeToUnix } from "../../utils/convertDate";
import useStyles from "./style";
import { getCampaigns } from "../../store/actions/campaign";
import { useCommonStyle } from "../../styles";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import Button from "../../components/Base/ButtonLink";
import PoolRecord from "./PoolsRecord";
import { adminRoute } from "../../utils";
import useContractDetail from "../StakingPoolCreate/hook/useContractDetail";
import {
  getContractInstance,
  getErc20Contract,
  getPoolContract,
} from "../../services/web3";
import { BASE_CHAIN_ID, USDC_BASE_ADDRESS } from "../../constants";
import BigNumber from "bignumber.js";
import stakingPoolABI from "../../abi/Staking/StakingPool.json";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { useHistory } from "react-router-dom";

const tableHeaders = ["POOL NAME", "POSITION", "VIEW STATUS", "STATUS", "POOL TYPE", "LAUNCHPAD POINT"];

const fetchListPool = async () => {
  let url = apiRoute(`/staking-pool`);

  try {
    const baseRequest = new BaseRequest();
    const response = (await baseRequest.get(url)) as any;
    const resObject = await response.json();

    if (resObject.status === 200) {
      const data = resObject.data;
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

interface Props {
  styles: any;
  networkAvailable: string;
  stakingPoolAddress: string;
}

const StakingPool = ({
  styles,
  networkAvailable,
  stakingPoolAddress,
}: Props) => {
  const { contractDetail, fetchContractDetail } = useContractDetail(
    networkAvailable,
    stakingPoolAddress
  );
  const [balance, setBalance] = useState<number>(0);
  const [symbol, setSymbol] = useState<string>("");
  const [rewardAddress, setRewardAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handle();
  }, [contractDetail]);
  const handle = async () => {
    const acceptToken = (contractDetail as any)?.linearAcceptedToken;
    const rewardAddress = (contractDetail as any)?.linearRewardDistributor;
    if (!acceptToken || !rewardAddress) return;
    setRewardAddress(rewardAddress);
    const ercContract = getErc20Contract({
      networkAvailable,
      erc20TokenAddress: acceptToken,
    });
    if (!ercContract) return;
    const [balance, decimal, symbol] = await Promise.all([
      ercContract.methods.balanceOf(rewardAddress).call(),
      ercContract.methods.decimals().call(),
      ercContract.methods.symbol().call(),
    ]);

    const balanceReturn = new BigNumber(balance)
      .div(new BigNumber(10).pow(+decimal || 18))
      .toFixed(4);
    setBalance(+balanceReturn);
    setSymbol(symbol);
  };

  const setRewardDistributor = async () => {
    try {
      setLoading(true);
      const contract = getContractInstance(
        stakingPoolABI,
        stakingPoolAddress || "",
        networkAvailable === "eth"
      );
      if (!contract) {
        throw new Error("Invalid contract");
      }
      await contract.methods.linearSetRewardDistributor(rewardAddress).call();
      alertSuccess("Update reward distributor address successfully");
      setLoading(false);
    } catch (e) {
      alertFailure("Update reward distributor address fail");
      setLoading(false);
    }
  };
  return (
    <div>
      <div className={styles.rewardDistributor}>
        <ButtonBase disabled={loading} onClick={() => setRewardDistributor()}>
          {loading ? "Loading..." : "Submit Reward Distributor"}
        </ButtonBase>
        <input
          placeholder="Reward Address"
          value={rewardAddress}
          disabled={loading}
          onChange={(e) => setRewardAddress(e?.target?.value)}
        />
        <div>
          Balance: {balance} {symbol}
        </div>
      </div>
    </div>
  );
};

const Pools: React.FC<any> = (props: any) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [stakingPools, setStakingPools] = useState<any>([]);

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  useEffect(() => {
    fetchListPool().then((data) => {
      setStakingPools(data);
    });
  }, []);

  return (
    <DefaultLayout>
      {/* {stakingPools && (
        <StakingPool
          styles={classes}
          networkAvailable={stakingPools[0]?.network_available}
          stakingPoolAddress={stakingPools[0]?.pool_address}
        />
      )} */}
      <div className={classes.header}>
        <div className="header-left">
          <Button
            to={adminRoute("/staking/add")}
            text={"Create New Pool"}
            icon={"icon_plus.svg"}
          />
        </div>
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={"100%"} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tableHeader: string, index: number) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tableHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {stakingPools &&
                stakingPools.length > 0 &&
                stakingPools.map((pool: any, index: number) => (
                  <PoolRecord key={pool.id} stakingPool={pool} />
                ))}
            </TableBody>
          </Table>
        )}
        {/* {
          failure ? <p className={classes.errorMessage}>{failure}</p> : ((!campaigns || campaigns.length === 0) && !loading)  ? <p className={classes.noDataMessage}>There is no data</p> : (
            <>
              {campaigns && lastPage > 1 && <Pagination page={currentPage} className={classes.pagination} count={lastPage} onChange={handlePaginationChange} />}
            </>
          )
        } */}
      </TableContainer>
    </DefaultLayout>
  );
};

export default Pools;
