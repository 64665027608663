import DefaultLayout from "../../../components/Layout/DefaultLayout";
import useStyles from "../SupplyCreate/style";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonLink from "../../../components/Base/ButtonLink";
import { adminRoute } from "../../../utils";
import SupplyDetail from "../SupplyCreate/SupplyDetail";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSupply } from "../../../request/supply";

interface MatchParams {
  id: string;
}

const SupplyEdit: React.FC<RouteComponentProps<MatchParams>> = (
  props: RouteComponentProps<MatchParams>
) => {
  const classes = useStyles();
  const [listSupply, setListSupply] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { match } = props;
  const id = match.params.id;

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  const getSupplyQuery = async () => {
    try {
      setLoading(true);
      const result = await getSupply();
      if (result?.data?.data) {
        const resultById = result?.data?.data?.find(
          (data: any) => data?.id === +id
        );
        if (resultById) {
          setListSupply(resultById);
        }
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSupplyQuery();
  }, []);

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div>
          <ButtonLink
            to={adminRoute("/supply")}
            text="Back"
            icon="icon-arrow-left.svg"
          >
            <img
              className="icon-back"
              src="/images/icon-arrow-left.svg"
              alt=""
            />
            Back
          </ButtonLink>
        </div>
      </div>
      {listSupply && (
        <div className="contentPage">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SupplyDetail
                suply={listSupply || null}
                loading={false}
                failure={false}
                isCreate={true}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </DefaultLayout>
  );
};

export default SupplyEdit;
