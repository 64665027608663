import DefaultLayout from "../../../components/Layout/DefaultLayout";
import useStyles from "./style";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import ButtonLink from "../../../components/Base/ButtonLink";
import { adminRoute } from "../../../utils";
import SupplyDetail from "./SupplyDetail";

const SupplyCreate: React.FC = () => {
  const classes = useStyles();
  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div>
          <ButtonLink
            to={adminRoute("/supply")}
            text="Back"
            icon="icon-arrow-left.svg"
          >
            <img
              className="icon-back"
              src="/images/icon-arrow-left.svg"
              alt=""
            />
            Back
          </ButtonLink>
        </div>
      </div>
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SupplyDetail
              suply={null}
              loading={false}
              failure={false}
              isCreate={true}
            />
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  );
};

export default SupplyCreate;
