import React, {useEffect} from 'react';
import useStyles from "../style";
import FormControl from '@material-ui/core/FormControl';
import {Controller} from "react-hook-form";
import {renderErrorCreatePool} from "../../../utils/validate";
import {Switch} from 'antd';
import {withRouter} from "react-router";

function PoolHighLight(props: any) {
  const classes = useStyles();
  const {
    setValue, errors, control,
    poolDetail,
  } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && (poolDetail.is_highlighted != undefined)) {
      setValue('is_highlighted', !!poolDetail.is_highlighted);
    }
  }, [poolDetail]);

  return (
    <>
      <div><label className={classes.formControlLabel}>View Status</label></div>
      <div style={{color: 'red'}}>Users will see the staking pool highlight or not</div>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="is_highlighted"
          render={(field) => {
            const { value, onChange } = field;
            return (
              <Switch
                onChange={ async (switchValue) => {
                  // eslint-disable-next-line no-restricted-globals
                  await onChange(switchValue);
                }}
                checked={!!value}
                checkedChildren="Highlight"
                unCheckedChildren="Normal"
              />
            )
          }}
        />

        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'is_highlighted')
          }
        </p>
      </FormControl>
      <br/>
    </>
  );
}

export default withRouter(PoolHighLight);
