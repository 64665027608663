import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    exportBtn: {
      padding: "13px 20px",
      backgroundColor: "#FFCC00",
      border: "none",
      borderRadius: 10,
      color: "white",
      fontWeight: 600,
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
    },
    tableContainer: {
      padding: "30px 20px",
      borderRadius: 10,
      boxShadow: "none",
      marginTop: 25,
    },
    table: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 14,
      backgroundColor: "white",
    },
    tableHeader: {
      padding: "0px 0px 25px 30px",
      color: "#363636",
      fontWeight: 600,
    },
    tableBody: {
      "& > .MuiTableRow-root:last-child": {
        borderBottom: "1px solid #E5E5E5",
      },
      "& > .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: "white",
      },
    },
    tableRow: {
      backgroundColor: "#f3f3f3",
      borderRadius: 5,

      "& > .MuiTableCell-root:first-child": {
        "border-top-left-radius": 5,
        "border-bottom-left-radius": 5,
      },

      "& > .MuiTableCell-root:last-child": {
        "border-top-right-radius": 5,
        "border-bottom-right-radius": 5,
      },
    },
    tableCell: {
      color: "#636363",
      fontSize: 14,
      fontWeight: 400,
      padding: "25px 30px",
      borderBottom: "none",

      "& .admin-status": {
        width: 10,
        height: 10,
        borderRadius: "50%",
        display: "inline-block",
        marginRight: 6,
      },

      "& .admin-paused": {
        backgroundColor: "red",
      },

      "& .admin-active": {
        backgroundColor: "#56b022",
      },

      "& .admin-suspend": {
        backgroundColor: "red",
      },

      "& .admin-processing": {
        backgroundColor: "#FFCC00",
      },
      "& .admin-inactive": {
        backgroundColor: "red",
      },
    },
    pagination: {
      marginTop: 30,
      fontSize: 12,
      fontWeight: 400,
      "& .MuiPagination-ul": {
        justifyContent: "center",
      },
      "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#FFCC00",
      },
    },
    skeleton: {
      padding: "25px 0px",
      marginTop: 10,
    },
    noDataMessage: {
      fontWeight: 500,
      marginTop: 30,
      textAlign: "center",
      fontSize: 15,
    },
    errorMessage: {
      fontWeight: 500,
      marginTop: 30,
      textAlign: "center",
      fontSize: 15,
      color: "red",
    },
  };
});

export default useStyles;
