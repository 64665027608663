import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  boxCampaignDetail: {
    borderRadius: 10,
    marginBottom: 20,
  },
  headBoxCampaignDetail: {
    background: '#FFCC00',
    borderRadius: '10px 10px 0px 0px',
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '& .campaign-active': {
      color: '#56b022',
    },
    '& .campaign-active::after': {
      backgroundColor: '#56b022'
    },
    '& .campaign-suspend': {
      color: 'red',
    },
    '& .campaign-suspend::after': {
      backgroundColor: 'red'
    },
  },
  titleBoxCampaignDetail: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    color: '#FFFFFF',
    fontFamily: 'Roboto-Bold',
  },
  formShow: {
    padding: '24px 50px',
    backgroundColor: 'white',
    borderRadius: 10,
    'border-top-left-radius': 0,
    'border-top-right-radius': 0,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)'
  },
  groupInput: {
    marginBottom: 30,
  },
  inputNumeric: {
   '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputLabel: {
    fontSize: 14,
    lineHeight: '22px',
    display: 'flex',
    letterSpacing: 0.1,
    color: '#363636',
    fontFamily: 'Roboto-Bold',
    marginBottom: 2,
  },
  inputG: {
    height: 40,
    border: '1px solid #DFDFDF',
    borderRadius: 5,
    width: '100%',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.25,
    color: '#636363',
    padding: '10px 15px',
  },
  boxInputGroupRateYouGet: {
    position: 'relative',
    display: 'block',
    background: 'transparent',
    border: '1px solid #DFDFDF',
    borderRadius: 5,
    overflow: 'hidden',

    '&.for': {
      background: '#DFDFFF',
      border: '1px solid #DFDFFF',

      '& .unit': {
        background: '#3A39BB',
      }
    },

    '& input': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent',
      outline: 'none',
      height: 40,
      padding: '10px 40px 10px 15px',
      display: 'block',
      width: '100%',
    },

    '& .unit': {
      fontSize: 14,
      letterSpacing: 0.25,
      color: '#FFFFFF',
      width: 50,
      height: 40,
      lineHeight: '40px',
      textAlign: 'center',
      position: 'absolute',
      right: 0,
      top: 0,
      background: '#000000',
    }
  },
  listBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnSubmit: {
    background: '#FFCC00',
    boxShadow: '0px 0px 15px rgba(243, 203, 25, 0.3)',
    borderRadius: 8,
    height: 40,
    minWidth: 92,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 160,
    alignItems: 'center',
    color: '#FFFFFF',
    textTransform: 'inherit',
    fontFamily: 'Roboto-Bold',
    overflow: 'hidden',
    marginRight: 20,
    '&:hover': {
      background: '#FFCC00',
    }
  },
}))

export default useStyles;
