import React, { useEffect, useState } from 'react';
import {RouteComponentProps, withRouter, useLocation, useHistory} from 'react-router-dom';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import {adminRoute} from "../../utils";
import PoolForm from "./PoolForm";
import BackButton from "../../components/Base/ButtonLink/BackButton";
import { useSelector } from 'react-redux';

const PoolCreate: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = false;
  const location : any = useLocation();
  const [poolDetailClone, setPoolDetailClone] = useState<any>({});

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  useEffect(() => {
    
    if (location.state?.poolDetail) {
      let poolData = { ...location.state?.poolDetail };
      poolData.id = null;
      console.log('Clone poolDetail', poolData);
      setPoolDetailClone(poolData);
    }
  }, [location]);

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/campaigns')}/>
      <PoolForm
        isEdit={isEdit}
        poolDetail={poolDetailClone}
      />
    </DefaultLayout>
  )
}

export default withRouter(PoolCreate);
