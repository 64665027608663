import React, { useEffect } from 'react';
import useStyles from "../style";
import { isValidAddress } from "../../../services/web3";
import { renderErrorCreatePool } from "../../../utils/validate";
import { useCommonStyle } from "../../../styles";
import { etherscanRoute } from "../../../utils";
import Link from "@material-ui/core/Link";

function AddPoolKeyPrice(props: any) {
    const classes = useStyles();
    const commonStyle = useCommonStyle();
    const {
        register, setValue, errors, needValidate,
        poolDetail,
    } = props;
    const renderError = renderErrorCreatePool;

    useEffect(() => {
        if (poolDetail && poolDetail.address_receiver) {
            setValue('coin_keys', poolDetail.coin_keys);
        }
    }, [poolDetail]);

    const isDeployed = !!poolDetail?.is_deploy;

    return (
        <>
            <div className={classes.formControl}>
                <label className={classes.formControlLabel}>Coin key</label>
                <input
                    type="text"
                    name="coin_keys"
                    ref={register({
                        required: needValidate,
                        validate: {
                            validAddress: (val: any) => {
                                if (!needValidate) return true;
                                return isValidAddress(val);
                            }
                        }
                    })}
                    style={{ width: "50%" }}
                    maxLength={255}
                    className={classes.formControlInput}
                    disabled={isDeployed}
                />
                <p className={classes.formErrorMessage}>
                    {
                        renderError(errors, 'coin_keys')
                    }
                </p>
                <p>Example Coin key = "chain-gpt". check token info in this link: <br /><a style={{ color: "#0058FF" }} href='https://api.cryptorank.io/v0/coins/crowdsales/values?coinKeys=chain-gpt' target="_blank" rel="noreferrer">https://api.cryptorank.io/v0/coins/crowdsales/values?coinKeys=chain-gpt</a></p>
            </div>
        </>
    );
}

export default AddPoolKeyPrice;
