import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { NETWORK_AVAILABLE } from "../../../constants";
import { getTiers } from "../../../request/tier";
import { renderErrorCreatePool } from "../../../utils/validate";
import useStyles from "../style";

function AcceptCurrency(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, watch, poolDetail } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.accept_currency) {
      setValue("acceptCurrency", poolDetail.accept_currency);
    }
  }, [poolDetail]);

  useEffect(() => {
    if (poolDetail && poolDetail.id) {
      getTiers(poolDetail.id).then((res) => {});
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;
  const networkAvailable = watch("networkAvailable");
  useEffect(() => {
    if (poolDetail.network_available !== networkAvailable) {
      setValue("acceptCurrency", "usdt");
    }
  }, [networkAvailable]);

  let showUsdt = true;
  let showUsdc = true;
  let showBusd = false;
  switch (networkAvailable) {
    case NETWORK_AVAILABLE.BSC:
      showUsdc = true;
      showBusd = true;
      break;
    case NETWORK_AVAILABLE.POLYGON:
      showUsdc = true;
      break;
    case NETWORK_AVAILABLE.AVALANCHE:
      showUsdc = false;
      break;
    case NETWORK_AVAILABLE.ARBITRUM:
      showUsdc = false;
      break;
    case NETWORK_AVAILABLE.BASE:
      showUsdc = true;
      showUsdt = false;
      break;
    default:
    // default is init value above
  }

  // console.log('userCurrentNetwork', isBscNetworks);

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Accepted Currency</label>

          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={
              poolDetail?.accept_currency ||
              (networkAvailable === NETWORK_AVAILABLE.BASE ? "usdc" : "usdt")
            }
            name="acceptCurrency"
            as={
              <RadioGroup row>
                {showBusd && (
                  <FormControlLabel
                    value="busd"
                    control={<Radio />}
                    label="BUSD"
                    disabled={isDeployed}
                  />
                )}
                {showUsdt && (
                  <FormControlLabel
                    value="usdt"
                    control={<Radio />}
                    label="USDT"
                    disabled={isDeployed}
                  />
                )}
                {showUsdc && (
                  <FormControlLabel
                    value="usdc"
                    control={<Radio />}
                    label="USDC"
                    disabled={isDeployed}
                  />
                )}
              </RadioGroup>
            }
          />
          <p className={classes.formErrorMessage}>{renderError(errors, "acceptCurrency")}</p>
        </FormControl>
      </div>
    </>
  );
}

export default AcceptCurrency;
