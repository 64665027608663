import React from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";

function PoolPostion(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail } = props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          Pool position
        </label>
        <input
          type="number"
          name="position"
          defaultValue={poolDetail?.position}
          min={0}
          ref={register()}
          className={classes.formControlInput}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "position")}
        </p>
      </div>
    </>
  );
}

export default PoolPostion;
