import useStyles from "./style";
import { useCommonStyle } from "../../../styles";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { createSupply, updateSupply } from "../../../request/supply";
import { useDispatch, useSelector } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { adminRoute } from "../../../utils";
import { useHistory } from "react-router";
import { useEffect } from "react";

const SupplyDetail = (props: any) => {
  const { suply, failure, isCreate } = props;
  const styles = useStyles();

  const history = useHistory();
  const { data: loginUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (!window.location.hash.includes("/login") && !loginUser?.id) {
      history.push("/");
    }
  }, [history, loginUser]);

  return (
    <div className={styles.boxCampaignDetail}>
      <div className={styles.headBoxCampaignDetail}>
        <h2 className={styles.titleBoxCampaignDetail}>
          {isCreate ? "Supply Create" : "Supply Detail"}
        </h2>
      </div>
      <div className="clearfix"></div>
      <div className={styles.formShow}>
        <FormSupply suply={suply || {}} isCreate={isCreate} />
      </div>
    </div>
  );
};

const FormSupply = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isCreate } = props;
  let { token_address, wallet_address, chain, type, name } = props.suply;
  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      token_address,
      wallet_address,
      chain,
      type,
      name,
    },
  });

  const onSubmit = (values: any) => {
    if (isCreate) {
      createSupply(values).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess("Create success"));
          history.push(adminRoute("/supply"));
        } else {
          dispatch(alertFailure(res.message || "Something went wrong"));
        }
      });
    } else {
      updateSupply(values).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess("Update success"));
          history.push(adminRoute("/supply"));
        } else {
          dispatch(alertFailure(res.message || "Something went wrong"));
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.groupInput}>
        <label className={styles.inputLabel}>
          Token Address
          <span className={commonStyle.required}>*</span>
        </label>
        <input
          className={styles.inputG}
          name="token_address"
          placeholder="Token Address"
          ref={register({ required: true })}
        />
        {errors.token_address && (
          <span className={commonStyle.error}>This field is required</span>
        )}
      </div>
      <div className="clearfix"></div>
      <div className={styles.groupInput}>
        <label className={styles.inputLabel}>
          Wallet Address
          <span className={commonStyle.required}>*</span>
        </label>
        <input
          className={styles.inputG}
          name="wallet_address"
          placeholder="Wallet Address"
          ref={register({ required: true })}
        />
        {errors.wallet_address && (
          <span className={commonStyle.error}>This field is required</span>
        )}
      </div>
      <div className="clearfix"></div>
      <div className={styles.groupInput}>
        <label className={styles.inputLabel}>
          Chain
          <span className={commonStyle.required}>*</span>
        </label>
        <input
          className={styles.inputG}
          name="chain"
          placeholder="Chain"
          ref={register({ required: true })}
        />
        {errors.chain && (
          <span className={commonStyle.error}>This field is required</span>
        )}
      </div>
      <div className="clearfix"></div>
      <div className={styles.groupInput}>
        <label className={styles.inputLabel}>
          Type
          <span className={commonStyle.required}>*</span>
        </label>
        <input
          className={styles.inputG}
          name="type"
          placeholder="Type"
          ref={register({ required: true })}
        />
        {errors.type && (
          <span className={commonStyle.error}>This field is required</span>
        )}
      </div>
      <div className="clearfix"></div>
      <div className={styles.groupInput}>
        <label className={styles.inputLabel}>
          Name
          <span className={commonStyle.required}>*</span>
        </label>
        <input
          className={styles.inputG}
          name="name"
          placeholder="Name"
          ref={register({ required: true })}
        />
        {errors.name && (
          <span className={commonStyle.error}>This field is required</span>
        )}
      </div>
      <div className="clearfix"></div>
      <div className={styles.listBtn}>
        <Button type="submit" className={styles.btnSubmit}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default SupplyDetail;
