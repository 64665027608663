import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getEnvironment } from "./utils/public";

const envLocal = localStorage?.getItem("env");
ReactDOM.render(
  <div className="default-loading-bg">
    <img
      className="loading-animation"
      src="/images/loading.svg"
      alt="loading"
    />
  </div>,
  document.getElementById("root")
);

getEnvironment()
  ?.then((env) => env?.json())
  ?.then((data) => {
    localStorage?.setItem("env", JSON.stringify(data));
    if (!envLocal) {
      window?.location.reload();
      return;
    }
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  ?.catch((e) => {
    ReactDOM.render(
      <div>Can not load environment</div>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
